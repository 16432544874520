import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import {ThemeProvider} from "styled-components";

import Portfolio from './component/Portfolio';

const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
})

export default function App() {

    return (

        <MuiThemeProvider theme={theme} >
            <ThemeProvider theme={theme}>
                <Portfolio />
            </ThemeProvider>
        </MuiThemeProvider>

    );
}