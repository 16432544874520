import styled from 'styled-components';

import atarCalcImg from "../../images/atar-calc.png";
import lightNotesImg from "../../images/light-notes.png";
import rockPoolsImg from "../../images/rock-pools.png";
import leNouveauMondeImg from "../../images/le-nouveau-monde.png";
import electricForestImg from "../../images/electric-forest.png";
import polarStringsImg from "../../images/polar-strings.png";

const Page = styled.div `
  padding: 20px 10px 20px 10px;
  font-family: Arial, sans-serif;
  background-color: #EEEEEE;
  min-height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div `
${({ theme }) => `
    height: 100%;
    width: 60%;
    ${theme.breakpoints.down('lg')} {
        width: 80%;
    }
    ${theme.breakpoints.down('sm')} {
      width: 95%;
  }
`}`;

const Title = styled.div `
${({ theme }) => `
  margin-bottom: 20px;

  border-radius: 100px;

  background-color: rgba(13, 50, 128, 0.9);
  font-size: 40px;
  color: rgba(255, 255, 255, 0.98);

  text-align: center;
  padding: 1rem;

  ${theme.breakpoints.down('xs')} {
    font-size: 24px;
    padding: 12px;
  }
`}`;

const Subtitle = styled.div `
${({ theme }) => `
  margin-bottom: 20px;

  border: solid rgba(0, 0, 0, 0.8);
  border-width: 4px 2px 4px 2px;
  border-radius: 100px;

  background-color: rgba(255, 255, 255, 0.98);
  font-size: 24px;
  font-weight: bold;
  color: rgba(10, 10, 10, 1);

  text-align: left;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;

  ${theme.breakpoints.down('sm')} {
    text-align: center;
    padding: 0.5rem;
  }

  ${theme.breakpoints.down('xs')} {
    font-size: 20px;
  }
`}`;

const PubGrid = styled.div `
${({ theme }) => `
  margin-bottom: 40px;

  min-height: 200px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 1rem;
  padding: 0.5rem;
  
  ${theme.breakpoints.down('xs')} {
    grid-template-columns: repeat(1, 1fr);
  }
`}`;

const PubItem = styled.div `
${({ theme }) => `

  min-height: 200px;

  padding: 1.5rem;

  background-color: rgba(255, 255, 255, 0.98);
  font-size: 18px;
  line-height: 1.25;
  color: rgba(10, 10, 10, 1);

  border: solid rgba(0, 0, 0, 0.8) 3px;
  border-radius: 10px;

  ${theme.breakpoints.down('xs')} {
    padding: 1rem;
    font-size: 14px;
  }
`}`;

const PubTitle = styled.div `
${({ theme }) => `
  color: rgba(10, 10, 10, 1);
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  ${theme.breakpoints.down('xs')} {
    font-size: 16px;
  }
`}`;

const PubImg = styled.img `
  width: 100%;
`

const Portfolio = () => {
  return (
    <Page id="portfolio" ><Content>
      <Title id="title">
        Andrew Gabb's Portfolio
      </Title>
      <Subtitle>
        Software
      </Subtitle>
      <PubGrid>
        <PubItem>
          <a href="https://synergynotes.com.au/atar-calculator" target="_blank" rel="noopener noreferrer"><PubTitle>ATAR Calculator</PubTitle></a>
          <p>As a Full Stack Engineer at Synergy Notes, I designed and built a clear, colourful and easy-to-use ATAR Calculator.</p><p>It uses a React / JavaScript Frontend, with a Java / Spring Backend hosted on an AWS S3 Bucket.</p>
          <a href="https://synergynotes.com.au/atar-calculator" target="_blank" rel="noopener noreferrer"><PubImg src={atarCalcImg} /></a>
        </PubItem>
        <PubItem>
          <a href="https://lightnotes.life/demo/" target="_blank" rel="noopener noreferrer"><PubTitle>Light Notes</PubTitle></a>
          <p>In early 2021 I released Light Notes, a stunning new notes app that uses React, AWS Amplify, and a modern UI to deliver a clean, aesthetic experience.</p>
          <p>View the GitHub Repo here: <a href="https://github.com/andrewrgabb/light-notes-v1" target="_blank" rel="noopener noreferrer">light-notes-v1</a></p>
          <a href="https://lightnotes.life/demo/" target="_blank" rel="noopener noreferrer"><PubImg src={lightNotesImg} /></a>
        </PubItem>
        <PubItem>
          <a href="https://apps.apple.com/au/app/rock-pools/id1352758412" target="_blank" rel="noopener noreferrer"><PubTitle>Rock Pools</PubTitle></a>
          <p>In 2020, I created and self-published Rock Pools to the iOS App Store.</p><p>It is written in Typescript and utilises the Cocos2d-x game engine to create a fun and relaxed experience.</p>
          <p>View the GitHub Repo here: <a href="https://github.com/andrewrgabb/rock-pools-v2_scripts" target="_blank" rel="noopener noreferrer">rock-pools-v2_scripts</a></p>
          <a href="https://apps.apple.com/au/app/rock-pools/id1352758412" target="_blank" rel="noopener noreferrer"><PubImg src={rockPoolsImg} /></a>
        </PubItem>
      </PubGrid>
      <Subtitle>
        Music
      </Subtitle>
      <PubGrid>
        <PubItem>
          <a href="https://soundcloud.com/marbyl/le-nouveau-monde" target="_blank" rel="noopener noreferrer"><PubTitle>Le Nouveau Monde</PubTitle></a>
          <p>A piano sonata influenced by both the classical style and modern digital music.</p>
          <a href="https://soundcloud.com/marbyl/le-nouveau-monde" target="_blank" rel="noopener noreferrer"><PubImg src={leNouveauMondeImg} /></a>
        </PubItem>
        <PubItem>
          <a href="https://soundcloud.com/marbyl/electric-forest" target="_blank" rel="noopener noreferrer"><PubTitle>Electric Forest</PubTitle></a>
          <p>Electronic Art music that combines elements from both the modern Electronic genre and the Romantic era of Western Classical Music.</p>
          <a href="https://soundcloud.com/marbyl/electric-forest" target="_blank" rel="noopener noreferrer"><PubImg src={electricForestImg} /></a>
        </PubItem>
        <PubItem>
          <a href="https://www.youtube.com/watch?v=eiz-R0eVQqU&ab_channel=AndrewGabb" target="_blank" rel="noopener noreferrer"><PubTitle>Polar Strings</PubTitle></a>
          <p>Romantic-era style music for the String Quartet.</p>
          <a href="https://www.youtube.com/watch?v=eiz-R0eVQqU&ab_channel=AndrewGabb" target="_blank" rel="noopener noreferrer"><PubImg src={polarStringsImg} /></a>
        </PubItem>
      </PubGrid>
    </Content></Page>
  );
}

export default Portfolio;
